import './Home.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getFirestore, getDocs, doc, updateDoc } from "firebase/firestore"
import { initializeApp } from "firebase/app";
import { GoogleGenerativeAI } from "@google/generative-ai";
import Cookies from "js-cookie";
import { SearchTopic } from './userdata';
import { History } from './userdata';

export default function HomePt() {
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate()
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });
    const backgrounds = [["Biologia Marinha", "marine-biology.png"], ["Reações Químicas", "chemical-reactions.jpeg"], ["Aquecimento Global", "climate-change.webp"], ["Guerra Fria", "cold-war.jpeg"], ["Geometria Analítica", "geometry.jpeg"], ["Leis de Newton", "newton-laws.jpeg"], ["Sistema Solar", "solar-system.jpeg"], ["Estequiometria", "stoichiometry.jpeg"],]
    const [currentBackground,] = useState(backgrounds[Math.floor(Math.random() * backgrounds.length)])
    const [isLoading, setIsLoading] = useState(false)
    const [historyBarOpened, setHistoryBarOpened] = useState(false)
    const [historyData, setHistoryData] = useState(new History())
    const [historyPerDate, setHistoryPerDate] = useState([[], [], []])

    useEffect(() => {
        const data = Cookies.get(`history`)
        if (data) {
            try {
                const parsedData = JSON.parse(data)
                setHistoryData(parsedData)
                setHistoryPerDate([
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneWeek = 7 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff < oneWeek
                    }),
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneWeek = 7 * 24 * 60 * 60 * 1000;
                        const oneMonth = 30 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff < oneMonth && diff > oneWeek
                    }),
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneMonth = 30 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff > oneMonth
                    }),
                ])
            } catch {
                console.log("couldn't get history data.")
            }
        } else {
            setHistoryData(new History())
            setHistoryPerDate([[], [], []])
        }
    }, [])

    const isItATopic = async () => {
        const result = await model.generateContent(`Responda unicamente com sim ou não: você consegue gerar um resumo para estudantes sobre "${inputValue}"? Se for uma palavra ou algo sem sentido, por favor, responda que não, mas preciso que sua resposta seja APENAS A PALAVRA sim, ou A PALAVRA não.`)
        const response = await result.response;
        const text = await response.text().toLowerCase();
        if (text === "não" || text === "nao") {
            return false
        } else {
            return true
        }
    }

    const getResponseForGivenPrompt = async (inputValue) => {
        try {
            var text = ""
            while (text === "") {
                const result = await model.generateContent(
                    `
                    Gere um tópico educacional detalhado sobre ${inputValue}.

                    O tópico deve incluir os seguintes componentes:

                    Language: A menos que o tema estiver declaradamente em outra linguagem (ex: Titanic="pt-BR", Liverpool="pt-BR", World War II="en-US"), isso tem que ser "pt-BR". Se não, coloque o locale em en gere as respostas a seguir em inglês.
                    Nome: O nome do tópico deve ser claro, preciso e diretamente relacionado ao assunto, sem explicações. O mais próximo possível de ${inputValue}.
                    Resumo: Escreva um resumo do tópico com aproximadamente 30 linhas. O resumo deve oferecer uma visão abrangente dos conceitos principais, incluindo contexto histórico e relevância. Utilize <bold> (e não **palavra**) para grifar as palavras chave e <p> para separar parágrafos. Não inclua títulos, listas, ou outras tags de formatação. O texto NÃO DEVE CONTER MARKDOWN e deve ser formatado estritamente em HTML. Lembre de grifar as palavras chave do resumo, mas não só o tópico em si, e sim as palavras chave!
                    Cartões: Crie cinco cartões, cada um com um termo-chave relacionado ao tópico e sua definição detalhada. Os cartões devem ser strings simples, e NÃO USE HTML AQUI.
                    Quiz: Elabore um quiz com cinco perguntas sobre o tópico. Cada pergunta deve incluir:
                    Uma resposta correta (especificada pela propriedade "answer", que deve ser 1, 2, 3 ou 4).
                    Quatro opções de resposta (opt1, opt2, opt3, opt4).
                    Para cada resposta incorreta, forneça uma explicação (opt1exp, opt2exp, opt3exp, opt4exp) respectivamente. Se a resposta for correta, a explicação deve ser uma string vazia. NÃO DEIXE DE FAZER ISSO

                    Formato de saída: A saída deve ser uma STRING JSON PURA e seguir exatamente a estrutura abaixo. Não inclua blocos de código, formatação extra, ou caracteres adicionais. A resposta deve ser formatada de forma que possa ser diretamente usada dentro de uma string cercada por crases em JavaScript.

                    { "id": "string", "language": "pt-BR", "name": "string", "resumo": "string", "cards": [ { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" } ], "quiz": { "questions": [ { "question": "string", "opt1": "string", "opt1exp": "string (se opt1 tiver errado, a explicação do por que. se não, string vazia)", "opt2": "string", "opt2exp": "string (se opt2 tiver errado, a explicação do por que. se não, string vazia)", "opt3": "string", "opt3exp": "string (se opt3 tiver errado, a explicação do por que. se não, string vazia)", "opt4": "string", "opt4exp": "string (se opt4 tiver errado, a explicação do por que. se não, string vazia)", "answer": "int (a opção certa. 1, 2, 3 ou 4)" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" } ] } }

                    Atenção: Não inclua nenhum bloco de código, como crase, crase, crase json, ou qualquer outra formatação extra. O JSON deve ser gerado em um formato que possa ser diretamente inserido em uma string cercada por crases em JavaScript, sem alterações.
                    Cuidado para não cair nos seguintes erros: (JSON Parse error: Property name must be a string literal), (JSON Parse error: Unexpected EOF), (JSON Parse error: Expected '}'), (JSON Parse error: Property name must be a string literal)
                    Problemas que estou tendo (tome cuidado pra não cair nesses): Resumo está vindo com muitos parágrafos e está ficando inútil, as palavras estão sendo grifadas assim: **exemplo**, resumo tá vindo muito pequeno (deve ser 30 linhas) // NÃO FAÇA NENHUMA DESSAS COISAS
                    `
                );
                const response = await result.response;
                text = await response.text();
                text = text.replace("```json", '').replace("```", '');
            }
            return text
        }
        catch (error) {
            console.log("Something Went Wrong");
        }
    }

    const idMaker = (string) => {
        return string
            .replace(/\s+/g, '-')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "");
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const revisai = async () => {
        setInputValue(inputValue.trim())
        if (inputValue.length > 1) {
            setIsLoading(true)
            const data = await getTopics()
            const id = idMaker(inputValue)
            if (data[id] === undefined) {
                const isIt = await isItATopic()
                if (isIt === true) {
                    const docRef = doc(db, "revisai", "topicos")
                    const prompt = await getResponseForGivenPrompt(inputValue)

                    try {
                        JSON.parse(prompt)
                        await updateDoc(docRef, { [id]: prompt })
                        const copyHistoryData = historyData
                        const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                        if (previousAccessId !== -1) {
                            copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                        } else {
                            const newTopic = new SearchTopic()
                            newTopic.name = inputValue
                            newTopic.id = id
                            newTopic.date = Date.now()
                            copyHistoryData.topicsViewed.push(newTopic)
                        }
                        setHistoryData(copyHistoryData)
                        saveCookies()
                        await navigate(`/${idMaker(inputValue)}`)
                    } catch (error) {
                        console.log(error)
                        await navigate(`/404`)
                    }

                } else {
                    await navigate(`/404`)
                }
            } else {
                const copyHistoryData = historyData
                const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                if (previousAccessId !== -1) {
                    copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                } else {
                    const newTopic = new SearchTopic()
                    newTopic.name = inputValue
                    newTopic.id = id
                    newTopic.date = Date.now()
                    copyHistoryData.topicsViewed.push(newTopic)
                }
                setHistoryData(copyHistoryData)
                saveCookies()
                await navigate(`/${id}`)
            }
        }
    }

    const revisaiParameter = async (p) => {
        if (p.length > 1) {
            setIsLoading(true)
            const data = await getTopics()
            const id = idMaker(p)
            if (data[id] === undefined) {
                const docRef = doc(db, "revisai", "topicos")
                const prompt = await getResponseForGivenPrompt(p)

                try {
                    JSON.parse(prompt)
                    await updateDoc(docRef, { [id]: prompt })
                    const copyHistoryData = historyData
                    const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                    if (previousAccessId !== -1) {
                        copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                    } else {
                        const newTopic = new SearchTopic()
                        newTopic.name = p
                        newTopic.id = id
                        newTopic.date = Date.now()
                        copyHistoryData.topicsViewed.push(newTopic)
                    }
                    setHistoryData(copyHistoryData)
                    saveCookies()
                    await navigate(`/${id}`)
                } catch (error) {
                    console.log(error)
                    await navigate(`/404`)
                }
            } else {
                const copyHistoryData = historyData
                const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                if (previousAccessId !== -1) {
                    copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                } else {
                    const newTopic = new SearchTopic()
                    newTopic.name = p
                    newTopic.id = id
                    newTopic.date = Date.now()
                    copyHistoryData.topicsViewed.push(newTopic)
                }
                setHistoryData(copyHistoryData)
                saveCookies()
                await navigate(`/${id}`)
            }
        }
    }

    function saveCookies() {
        Cookies.set("history", JSON.stringify(historyData));
    }


    const firebaseApp = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_KEY,
        authDomain: "revisai-a1749.firebaseapp.com",
        projectId: "revisai-a1749",
    });

    const db = getFirestore(firebaseApp);
    const userCollectionRef = collection(db, "revisai")

    const getTopics = async () => {
        const data = await getDocs(userCollectionRef)
        return data.docs[0].data()
    }

    const handleKeyDown = (evt) => {
        if (evt.key === "Enter") {
            revisai()
        }
    }

    return (
        <div className="revisai-home" onKeyDown={handleKeyDown}>
            <div className='revisai-background' onClick={() => setHistoryBarOpened(false)}><img src={`./${currentBackground[1]}`} alt='background'></img></div>

            <div
                className={`historybar ${historyBarOpened ? 'open' : ''}`}
                onMouseLeave={() => setHistoryBarOpened(false)}
            >
                <div className='historybar-titulo'>Histórico</div>
                <div className='historybar-separador'></div>

            {
                    historyPerDate[0].length === 0 && historyPerDate[1].length === 0 && historyPerDate[2].length === 0 ?
                    <div className='historybar-filler'>
                        Ainda não tem nada aqui
                    </div> :
                    <></>
            }

                {historyPerDate[0].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Essa semana</div>
                        {historyPerDate[0].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                {historyPerDate[1].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Esse mês</div>
                        {historyPerDate[1].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                {historyPerDate[2].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Antigo</div>
                        {historyPerDate[2].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                <div style={{ margin: "30px" }}></div>
            </div>

            <div className='revisai-content'>
                <nav>
                    <div className="icon">
                        <a href="#"><img src="./logobranco.png" alt="logo" /></a>
                        <div className="icon-title">Beta (Acesso Antecipado)</div>
                    </div>
                    <button className='menu-button' onClick={() => setHistoryBarOpened(true)}><img src='./menu.png' alt='menu' /></button>
                </nav>


                <div className="center-block">
                    <div className="text">
                        <div className="title">Revise e aprenda tudo sobre <span className='home-topic' onClick={() => revisaiParameter(currentBackground[0])}>{currentBackground[0]}</span></div>
                        <div className="subtitle">A plataforma para te ajudar a revisar seus assuntos de maneira inteligente!</div>
                    </div>
                </div>

                <input type="text" maxLength={45} placeholder="Teoria da Relatividade" value={inputValue} onChange={handleInputChange} />

                <button className='submit' onClick={revisai} disabled={inputValue.length <= 1 || isLoading}>
                    <img src={isLoading ? "./spinner.svg" : "./textobotao.png"} alt="botão escrito revisai!" />
                </button>
            </div>
        </div>
    )
}